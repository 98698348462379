import React from "react";
import { Col, Row } from "reactstrap";
import CheckSign from "../../components/assets/check-sign.png";
import PowerCard1 from "../../components/assets/power-card-1.png";
import PowerCard2 from "../../components/assets/power-card-2.png";
import powerTile1 from "../../components/assets/power-tile-1.png";
import powerTile2 from "../../components/assets/power-tile-2.png";
import powerTile3 from "../../components/assets/power-tile-3.png";
import powerTile4 from "../../components/assets/power-tile-4.png";
import powerTile5 from "../../components/assets/power-tile-5.png";
import PDUPdf from "../../components/assets/PDF/PDU-specification.pdf";
import PCUPdf from "../../components/assets/PDF/PCU-specification.pdf";
import downloadmain from "../../components/assets/downloadmain.png";
function PowerTiles() {
  const powerArray1 = [
    {
      heading: "Over Temperature Protection",

      img: powerTile1,
    },
    {
      heading: "Short Circuit Protection",
      img: powerTile2,
    },
    {
      heading: "Low Power Consumption",
      img: powerTile3,
    },
    {
      heading: "Upto 100A Continuous Current",
      img: powerTile4,
    },
  ];
  const powerArray2 = [
    {
      heading: "Contactor Weld Check",

      img: powerTile1,
    },
    {
      heading: ">1µs Short Circuit Protection",
      img: powerTile2,
    },
    {
      heading: "Hardware Interlock",
      img: powerTile5,
    },
    {
      heading: "Supports Current >250A",
      img: powerTile4,
    },
  ];
  const powerArray3 = [
    "Automotive Grade Components",
    "Precharging",
    "Onboard Current Measurement",
  ];
  const powerArray4 = [
    "Automotive Grade Components",
    "Fuse Burn Check",
    "Inbuilt Power Supply up to 12-68 V",
  ];

  return (
    <div>
      <div className="flex">
        <div className=" h-1 w-6/12 bg-slate-100 rounded-full mr-4 mt-4"></div>
        <h4 className="uppercase font-bold ">Power Management System</h4>
        <div className="ml-4 mt-4 items-left rounded-full h-1 w-6/12 bg-slate-100"></div>
      </div>
      <Row style={{ margin: "1rem" }}>
        <Col md="6" lg="6" xl="6" sm="12" xs="12">
          <div className="power-card-tile-inner">
            <h5>Power Distribution Unit</h5>
            <Row>
              <Col md="6" lg="6" xl="6" sm="12" xs="12">
                <div
                  style={{
                    backgroundColor: "rgba(256,256,256,0.5",
                    borderRadius: "1rem",
                    padding:'10px 5px 5px 5px'
                  }}
                >
                  <img
                    src={PowerCard1}
                    alt=""
                  ></img>
                  <br />
                  <p style={{  color: "#1150A1", padding:'10px' }}>
                    This Card is MOSFET based and supports upto 100A
                    of Continuous Current.
                    <br />
                  </p>
                  <br />
                  <button className="specification-button">
                    <Row>
                      <Col>
                        <img src={downloadmain} alt="" />
                      </Col>
                      <Col>
                        <a
                          href={PDUPdf}
                          download="PDU-specification"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "whitesmoke",
                          }}
                        >
                          Specification
                        </a>
                      </Col>
                    </Row>
                  </button>
                </div>
              </Col>
              <Col md="6" lg="6" xl="6" sm="12" xs="12">
                <Row>
                  {powerArray1.map((val, i) => (
                    <Col md="6" lg="6" xl="6" sm="12" xs="12" key={i}>
                      <div className="bms-tile-box">
                        <Row>
                          <Col>
                            <img
                              src={val.img}
                              alt=""
                              className="power-tile-img-big"
                            ></img>
                            <br />
                            <strong className="power-tile-heading">
                              {val.heading}
                            </strong>
                            <br />
                            <strong className="power-tile-sub-heading">
                              {val.subHeading}
                            </strong>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              {powerArray3.map((val, i) => (
                <Col md="4" lg="4" xl="4" sm="12" xs="12" key={i}>
                  <div className="bms-tile-box verticle-center-align">
                    <Row>
                      <Col md="3" lg="3" xl="3" sm="3" xs="3">
                        <img
                          src={CheckSign}
                          alt=""
                          className="check-img"
                        ></img>
                      </Col>
                      <Col md="9" lg="9" xl="9" sm="9" xs="9">
                        <strong className="bms-tile-strong">{val}</strong>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Col>
        <Col md="6" lg="6" xl="6" sm="12" xs="12">
          <div className="power-card-tile-inner">
            <h5>Power Control Unit</h5>
            <Row>
              <Col md="6" lg="6" xl="6" sm="12" xs="12">
                <div
                  style={{
                    backgroundColor: "rgba(256,256,256,0.5",
                    borderRadius: "1rem",
                    padding:'10px 5px 5px 5px'
                  }}
                >
                  <img
                    src={PowerCard2}
                    alt=""
                    // style={{ maxWidth: "20rem", maxHeight: "15rem" }}
                  ></img>
                  <br />
                  <p style={{  color: "#1150A1" , padding:'10px'}}>
                    Controls card operating with contactor, hall sensor and
                    pre-charge relay, generally used for application for 100A+
                    of current.
                  </p>
                  <button className="specification-button">
                    <Row>
                      <Col>
                        <img src={downloadmain} alt="" />
                      </Col>
                      <Col>
                        <a
                          href={PCUPdf}
                          download="PCU-specification"
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "whitesmoke",
                          }}
                        >
                          Specification
                        </a>
                      </Col>
                    </Row>
                  </button>
                </div>
              </Col>
              <Col md="6" lg="6" xl="6" sm="12" xs="12">
                <Row>
                  {powerArray2.map((val, i) => (
                    <Col md="6" lg="6" xl="6" sm="12" xs="12" key={i}>
                      <div className="bms-tile-box">
                        <Row>
                          <Col>
                            <img
                              src={val.img}
                              alt=""
                              className="power-tile-img-big"
                            ></img>
                            <br />
                            <strong className="power-tile-heading">
                              {val.heading}
                            </strong>
                            <br />
                            <strong className="power-tile-sub-heading">
                              {val.subHeading}
                            </strong>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              {powerArray4.map((val, i) => (
                <Col md="4" lg="4" xl="4" sm="12" xs="12" key={i}>
                  <div className="bms-tile-box verticle-center-align">
                    <Row>
                      <Col md="3" lg="3" xl="3" sm="3" xs="3">
                        <img
                          src={CheckSign}
                          alt=""
                          className="check-img"
                        ></img>
                      </Col>
                      <Col md="9" lg="9" xl="9" sm="9" xs="9">
                        <strong className="bms-tile-strong">{val}</strong>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PowerTiles;
